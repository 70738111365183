import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/AIDescriptionGenerator.css';

const AIAttributeGenerator = ({ selectedProducts, onComplete }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 0 });
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const MAX_RETRIES = 3;
    const BATCH_SIZE = 1; // Process one at a time
    const DELAY_BETWEEN_REQUESTS = 500; // 500ms delay between requests

    const generateAttributes = useCallback(async () => {
        if (!selectedProducts.length) {
            setError('No products selected');
            return;
        }

        setIsGenerating(true);
        setError(null);
        setProgress({ current: 0, total: selectedProducts.length });

        let currentIndex = 0;
        const token = localStorage.getItem('accessToken');

        while (currentIndex < selectedProducts.length) {
            try {
                const currentBatch = selectedProducts.slice(
                    currentIndex,
                    currentIndex + BATCH_SIZE
                );

                const response = await axios.post(
                    `${config.apiUrl}/api/products/batch-extract-attributes/`,
                    {
                        product_ids: currentBatch,
                        current_index: currentIndex
                    },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                        timeout: 30000
                    }
                );

                // Update progress
                currentIndex += BATCH_SIZE;
                setProgress({
                    current: Math.min(currentIndex, selectedProducts.length),
                    total: selectedProducts.length
                });

                // Add delay between requests
                await new Promise(resolve => setTimeout(resolve, DELAY_BETWEEN_REQUESTS));

                if (currentIndex >= selectedProducts.length) {
                    break;
                }

            } catch (error) {
                if (retryCount < MAX_RETRIES) {
                    setRetryCount(prev => prev + 1);
                    await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1s before retry
                    continue;
                }
                setError('Failed to generate attributes. Please try again.');
                break;
            }
        }

        setIsGenerating(false);
        setRetryCount(0);
        if (!error) {
            onComplete();
        }
    }, [selectedProducts, retryCount, error, onComplete]);

    return (
        <div className="ai-generator-container">
            <button
                onClick={generateAttributes}
                disabled={isGenerating || !selectedProducts.length}
                className="generate-button"
            >
                {isGenerating ? 'Generating...' : 'Generate AI Attributes'}
            </button>

            {isGenerating && (
                <div className="progress-container">
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{ width: `${(progress.current / progress.total) * 100}%` }}
                        />
                    </div>
                    <div className="progress-text">
                        {progress.current} of {progress.total} completed
                    </div>
                </div>
            )}

            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default AIAttributeGenerator; 