import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/addProduct.css';  //
import { FaPencilAlt, FaTimes, FaMagic } from 'react-icons/fa';

const EditProductPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        old_price: '',
        stock: '',
        status: '',
        category: '',
        imageUrl1: '',
        imageUrl2: '',
        imageUrl3: '',
        imageUrl4: '',
        url: '',
        slug: '',
        manufacturer: '',
        model: '',
        capacity: '',
        ram: '',
        color: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [showAddReview, setShowAddReview] = useState(false);
    const [newReview, setNewReview] = useState({
        title: '',
        description: '',
        url: ''
    });
    const [editingReview, setEditingReview] = useState(null);
    const [isChecking, setIsChecking] = useState(false);
    const [isGeneratingAttributes, setIsGeneratingAttributes] = useState(false);

    const STATUS_CHOICES = {
        DRAFT: 'Draft',
        PUBLISHED: 'Published',
        OUT_OF_STOCK: 'Out of Stock',
        ARCHIVED: 'Archived'
    };

    const fetchProduct = useCallback(async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.get(
                `${config.apiUrl}/api/products/products/${id}/`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            const product = response.data;
            setFormData({
                name: product.name,
                description: product.description,
                price: product.price.toString(),
                old_price: product.old_price?.toString() || '',
                stock: product.stock.toString(),
                category: product.category.toString(),
                status: product.status,
                imageUrl1: product.images[0] || '',
                imageUrl2: product.images[1] || '',
                imageUrl3: product.images[2] || '',
                imageUrl4: product.images[3] || '',
                url: product.url || '',
                slug: product.slug || '',
                manufacturer: product.manufacturer || '',
                model: product.model || '',
                capacity: product.capacity || '',
                ram: product.ram || '',
                color: product.color || ''
            });
        } catch (err) {
            console.error('Fetch error:', err);
            setError('Failed to load product');
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchProduct();
    }, [fetchProduct]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) return;

            const response = await axios.get(
                `${config.apiUrl}/api/products/categories/`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setCategories(response.data.data || []);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const generateSlug = async () => {
        try {
            setError('');  // Clear any previous errors
            const token = localStorage.getItem('accessToken');

            if (!formData.name) {
                setError('Product name is required to generate slug');
                return;
            }

            const response = await axios.post(
                `${config.apiUrl}/api/products/generate-slug/`,
                { product_name: formData.name },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data) {
                setFormData(prev => ({
                    ...prev,
                    slug: response.data
                }));
            } else {
                setError('Failed to generate slug: No response from server');
            }
        } catch (error) {
            console.error('Error generating slug:', error);
            setError(
                error.response?.data?.error ||
                error.response?.data?.message ||
                'Failed to generate slug. Please try again.'
            );
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const token = localStorage.getItem('accessToken');
            const payload = {
                name: formData.name.trim(),
                description: formData.description.trim() || " ",
                price: parseFloat(formData.price),
                old_price: formData.old_price ? parseFloat(formData.old_price) : null,
                stock: parseInt(formData.stock),
                category: parseInt(formData.category),
                status: formData.status,
                images: [
                    formData.imageUrl1,
                    formData.imageUrl2,
                    formData.imageUrl3,
                    formData.imageUrl4
                ].filter(url => url.trim()),
                url: formData.url?.trim() || null,
                slug: formData.slug?.trim() || null,
                manufacturer: formData.manufacturer?.trim() || null,
                model: formData.model?.trim() || null,
                capacity: formData.capacity?.trim() || null,
                ram: formData.ram?.trim() || null,
                color: formData.color?.trim() || null
            };

            console.log('Sending payload:', payload);

            const response = await axios.put(
                `${config.apiUrl}/api/products/products/${id}/`,
                payload,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data) {
                console.log('Update response:', response.data);
                navigate('/dashboard/products');
            }
        } catch (err) {
            console.error('Update error:', err.response?.data || err);
            setError(
                err.response?.data?.error ||
                err.response?.data?.message ||
                'Failed to update product'
            );
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this product? This action cannot be undone.')) {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    navigate('/login');
                    return;
                }

                await axios.delete(
                    `${config.apiUrl}/api/products/products/${id}/`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                navigate('/dashboard/products');
            } catch (err) {
                setError(err.response?.data?.message || 'Failed to delete product');
            }
        }
    };

    const handleGenerateDescription = async () => {
        if (!formData.name) {
            setError('Product name is required to generate description');
            return;
        }

        try {
            setIsLoading(true);
            setError('');  // Clear any previous errors
            const token = localStorage.getItem('accessToken');

            const response = await axios.post(
                `${config.apiUrl}/api/products/generate-description/`,
                {
                    product_name: formData.name,
                    existing_description: formData.description
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.description) {
                setFormData(prev => ({
                    ...prev,
                    description: response.data.description
                }));
            } else {
                setError('Failed to generate description: No response from server');
            }
        } catch (error) {
            console.error('Error generating description:', error);
            setError(
                error.response?.data?.error ||
                error.response?.data?.message ||
                'Failed to generate description. Please try again.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const fetchReviews = useCallback(async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.get(
                `${config.apiUrl}/api/products/products/${id}/reviews/`,
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );
            setReviews(response.data);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    }, [id]);

    useEffect(() => {
        fetchReviews();
    }, [fetchReviews]);

    const handleReviewSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('accessToken');
            await axios.post(
                `${config.apiUrl}/api/products/products/${id}/add_review/`,
                newReview,
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );
            setShowAddReview(false);
            setNewReview({ title: '', description: '', url: '' });
            fetchReviews();
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to add review');
        }
    };

    const handleDeleteReview = async (reviewId) => {
        if (window.confirm('Are you sure you want to delete this review?')) {
            try {
                const token = localStorage.getItem('accessToken');
                await axios.delete(
                    `${config.apiUrl}/api/products/products/${id}/reviews/${reviewId}/`,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                fetchReviews();
            } catch (error) {
                setError(error.response?.data?.message || 'Failed to delete review');
            }
        }
    };

    const handleEditReview = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('accessToken');
            await axios.put(
                `${config.apiUrl}/api/products/products/${id}/reviews/${editingReview.id}/`,
                editingReview,
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );
            setEditingReview(null);
            fetchReviews();
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to update review');
        }
    };

    const handleGenerateReview = async () => {
        if (!formData.name) {
            setError('Product name is required to generate review');
            return;
        }

        try {
            setIsLoading(true);
            setError('');
            const token = localStorage.getItem('accessToken');

            const response = await axios.post(
                `${config.apiUrl}/api/products/products/${id}/generate_review/`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (response.data) {
                if (editingReview) {
                    // If we're editing, update the existing review
                    setEditingReview({
                        ...editingReview,
                        title: response.data.title,
                        description: response.data.description,
                        url: response.data.url
                    });
                } else {
                    // If we're adding new, update the newReview state
                    setNewReview({
                        title: response.data.title,
                        description: response.data.description,
                        url: response.data.url
                    });
                }
            } else {
                setError('Failed to generate review: No response from server');
            }
        } catch (error) {
            console.error('Error generating review:', error);
            setError(
                error.response?.data?.error ||
                error.response?.data?.message ||
                'Failed to generate review. Please try again.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const checkUrl = async (url) => {
        if (!url) return;
        const urlInput = document.querySelector('input[name="url"]');

        try {
            setIsChecking(true);
            const token = localStorage.getItem('accessToken');

            const response = await axios.get(
                `${config.apiUrl}/api/products/check-url/`,
                {
                    params: { url },
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );

            if (response.data.is_valid) {
                // URL is valid - change input background color and status to PUBLISHED
                urlInput.style.backgroundColor = 'palegreen';
                await axios.patch(
                    `${config.apiUrl}/api/products/products/${id}/`,
                    { status: 'PUBLISHED' },
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                fetchProduct();
            } else if (response.data.status === 404) {
                // URL is not found - change input background color and status to OUT_OF_STOCK
                urlInput.style.backgroundColor = '#ffcdd2'; // Light red background
                await axios.patch(
                    `${config.apiUrl}/api/products/products/${id}/`,
                    { status: 'OUT_OF_STOCK' },
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                fetchProduct();
            }
        } catch (error) {
            console.error('Error checking URL:', error);
            // Optionally show error to user
            setError('Failed to check URL. Please try again.');
            // Reset background color on error
            urlInput.style.backgroundColor = '';
        } finally {
            setIsChecking(false);
        }
    };

    const handleGenerateAttributes = async () => {
        if (!formData.name) {
            setError('Product name is required to generate attributes');
            return;
        }

        try {
            setIsGeneratingAttributes(true);
            setError('');
            const token = localStorage.getItem('accessToken');

            const response = await axios.post(
                `${config.apiUrl}/api/products/extract-attributes/`,
                {
                    product_name: formData.name
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success && response.data.data) {
                setFormData(prev => ({
                    ...prev,
                    manufacturer: response.data.data.manufacturer || '',
                    model: response.data.data.model || '',
                    capacity: response.data.data.capacity || '',
                    ram: response.data.data.ram || '',
                    color: response.data.data.color || ''
                }));
            } else {
                setError('Failed to generate attributes: No response from server');
            }
        } catch (error) {
            console.error('Error generating attributes:', error);
            setError(
                error.response?.data?.error ||
                error.response?.data?.message ||
                'Failed to generate attributes. Please try again.'
            );
        } finally {
            setIsGeneratingAttributes(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="add-product-page">
            <div className="add-product-header">
                <h2>Edit Product</h2>
                <button
                    className="back-button"
                    onClick={() => navigate('/dashboard/products')}
                >
                    <i className="fas fa-arrow-left"></i> Back
                </button>
            </div>

            {error && <div className="error-message">{error}</div>}

            <form onSubmit={handleSubmit} className="add-product-form">
                <div className="form-group">
                    <label>Product Name *</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        maxLength="200"
                        placeholder="Enter product name"
                    />
                </div>

                <div className="form-group">
                    <label>Description</label>
                    <div className="input-with-button">
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="form-control"
                            rows="4"
                        />
                        <button
                            type="button"
                            onClick={handleGenerateDescription}
                            className="ai-button description"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Generating...' : 'AI'}
                        </button>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Price *</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            required
                            step="0.01"
                            min="0"
                            placeholder="0.00"
                        />
                    </div>

                    <div className="form-group">
                        <label>Old Price</label>
                        <input
                            type="number"
                            name="old_price"
                            value={formData.old_price}
                            onChange={handleChange}
                            step="0.01"
                            min="0"
                            placeholder="0.00"
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Stock *</label>
                        <input
                            type="number"
                            name="stock"
                            value={formData.stock}
                            onChange={handleChange}
                            required
                            min="0"
                            placeholder="0"
                        />
                    </div>

                    <div className="form-group">
                        <label>Status *</label>
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            required
                        >
                            {Object.entries(STATUS_CHOICES).map(([value, label]) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-group">
                    <label>Category *</label>
                    <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select category</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label>Slug</label>
                    <div className="slug-input-container" style={{ display: 'flex', gap: '10px' }}>
                        <input
                            type="text"
                            name="slug"
                            value={formData.slug}
                            onChange={handleChange}
                            placeholder="Product slug"
                            style={{ flex: 1 }}
                        />
                        <button
                            type="button"
                            onClick={generateSlug}
                            className="ai-button"
                            style={{
                                padding: '8px 16px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            AI
                        </button>
                    </div>
                    <small>Product URL slug - Click AI to generate from product name</small>
                </div>

                <div className="form-group">
                    <label>Image URLs</label>
                    <div className="image-urls-container">
                        <input
                            type="url"
                            name="imageUrl1"
                            value={formData.imageUrl1}
                            onChange={handleChange}
                            placeholder="Enter image URL 1"
                            className="image-url-input"
                        />
                        <input
                            type="url"
                            name="imageUrl2"
                            value={formData.imageUrl2}
                            onChange={handleChange}
                            placeholder="Enter image URL 2"
                            className="image-url-input"
                        />
                        <input
                            type="url"
                            name="imageUrl3"
                            value={formData.imageUrl3}
                            onChange={handleChange}
                            placeholder="Enter image URL 3"
                            className="image-url-input"
                        />
                        <input
                            type="url"
                            name="imageUrl4"
                            value={formData.imageUrl4}
                            onChange={handleChange}
                            placeholder="Enter image URL 4"
                            className="image-url-input"
                        />
                    </div>
                    <small>Enter up to 4 image URLs. Empty URLs will be ignored.</small>
                </div>

                <div className="form-group">
                    <label>Product URL (Optional)</label>
                    <div className="input-with-button">
                        <input
                            type="url"
                            name="url"
                            value={formData.url}
                            onChange={handleChange}
                            placeholder="Enter product URL"
                        />
                        <button
                            type="button"
                            onClick={() => checkUrl(formData.url)}
                            className="ai-button url-check"
                            disabled={!formData.url || isChecking}
                        >
                            {isChecking ? 'Checking...' : 'Check'}
                        </button>
                    </div>
                    <small>External link to the product (if available)</small>
                </div>

                <div className="form-group">
                    <label>Manufacturer</label>
                    <div className="input-with-button">
                        <input
                            type="text"
                            name="manufacturer"
                            value={formData.manufacturer || ''}
                            onChange={handleChange}
                            maxLength="100"
                            placeholder="Enter manufacturer"
                        />
                        <button
                            type="button"
                            onClick={handleGenerateAttributes}
                            className="ai-button attribute"
                            disabled={isGeneratingAttributes || !formData.name}
                        >
                            {isGeneratingAttributes ? 'Generating...' : 'AI'}
                        </button>
                    </div>
                </div>

                <div className="form-group">
                    <label>Model</label>
                    <input
                        type="text"
                        name="model"
                        value={formData.model || ''}
                        onChange={handleChange}
                        maxLength="100"
                        placeholder="Enter model"
                    />
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Capacity</label>
                        <input
                            type="text"
                            name="capacity"
                            value={formData.capacity || ''}
                            onChange={handleChange}
                            maxLength="50"
                            placeholder="Enter capacity"
                        />
                    </div>

                    <div className="form-group">
                        <label>RAM</label>
                        <input
                            type="text"
                            name="ram"
                            value={formData.ram || ''}
                            onChange={handleChange}
                            maxLength="50"
                            placeholder="Enter RAM"
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label>Color</label>
                    <input
                        type="text"
                        name="color"
                        value={formData.color || ''}
                        onChange={handleChange}
                        maxLength="50"
                        placeholder="Enter color"
                    />
                </div>

                <div className="form-actions">
                    <button
                        type="button"
                        className="cancel-button"
                        onClick={() => navigate('/dashboard/products')}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="delete-button"
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                    <button
                        type="submit"
                        className="submit-button"
                        disabled={loading}
                    >
                        {loading ? 'Updating...' : 'Update Product'}
                    </button>
                </div>
            </form>

            <div className="reviews-section">
                <h3>Reviews</h3>
                {reviews.length > 0 ? (
                    <div className="existing-reviews">
                        {reviews.map(review => (
                            <div key={review.id} className="review-item">
                                {editingReview?.id === review.id ? (
                                    <form onSubmit={handleEditReview} className="review-form">
                                        <div className="form-group">
                                            <label>Title *</label>
                                            <input
                                                type="text"
                                                value={editingReview.title}
                                                onChange={(e) => setEditingReview({
                                                    ...editingReview,
                                                    title: e.target.value
                                                })}
                                                required
                                                maxLength="100"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Description *</label>
                                            <textarea
                                                value={editingReview.description}
                                                onChange={(e) => setEditingReview({
                                                    ...editingReview,
                                                    description: e.target.value
                                                })}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>URL *</label>
                                            <input
                                                type="url"
                                                value={editingReview.url}
                                                onChange={(e) => setEditingReview({
                                                    ...editingReview,
                                                    url: e.target.value
                                                })}
                                                required
                                                maxLength="500"
                                            />
                                        </div>
                                        <div className="review-form-actions">
                                            <button type="button" onClick={() => setEditingReview(null)}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="submit-button">
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="ai-button"
                                                onClick={() => handleGenerateReview()}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? 'Generating...' : 'Generate Review'}
                                            </button>
                                        </div>
                                    </form>
                                ) : (
                                    <>
                                        <a href={review.url} target="_blank" rel="noopener noreferrer">
                                            {review.url}
                                        </a>
                                        <div className="review-actions">
                                            <button
                                                className="icon-button"
                                                onClick={() => setEditingReview(review)}
                                            >
                                                <FaPencilAlt />
                                            </button>
                                            <button
                                                className="icon-button delete"
                                                onClick={() => handleDeleteReview(review.id)}
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No reviews yet.</p>
                )}

                {!showAddReview ? (
                    <button
                        className="add-review-button"
                        onClick={() => setShowAddReview(true)}
                    >
                        Add Review
                    </button>
                ) : (
                    <form onSubmit={handleReviewSubmit} className="review-form">
                        <div className="form-group">
                            <label>Title *</label>
                            <input
                                type="text"
                                value={newReview.title}
                                onChange={(e) => setNewReview(prev => ({
                                    ...prev,
                                    title: e.target.value
                                }))}
                                required
                                maxLength="100"
                            />
                        </div>
                        <div className="form-group">
                            <label>Description *</label>
                            <textarea
                                value={newReview.description}
                                onChange={(e) => setNewReview(prev => ({
                                    ...prev,
                                    description: e.target.value
                                }))}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>URL *</label>
                            <input
                                type="url"
                                value={newReview.url}
                                onChange={(e) => setNewReview(prev => ({
                                    ...prev,
                                    url: e.target.value
                                }))}
                                required
                                maxLength="500"
                            />
                        </div>
                        <div className="review-form-actions">
                            <button type="button" onClick={() => setShowAddReview(false)}>
                                Cancel
                            </button>
                            <button type="submit" className="submit-button">
                                Save Review
                            </button>
                            <button
                                type="button"
                                className="ai-button"
                                onClick={() => handleGenerateReview()}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Generating...' : 'Generate Review'}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default EditProductPage; 